import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// Api Service
import { api, api_delete, api_update } from '../api.service';
// Components
import Confirm from '../components/confirm';
// Forms
import CheckboxField from './fields/checkbox-field';
import CurrencyField from './fields/currency-field';
import DateField from './fields/date-field';
import FileField from './fields/file-field';
import PhoneField from './fields/phone-field';
import SelectField, { ADD_VENDOR_OPTION_ID } from './fields/select-field';
import TextField from './fields/text-field';
import FormWrapper from './form-wrapper';
import PayLaterButton from '../components/pay-later-button'
import { IconMoneybag } from '@tabler/icons-react';
import { toast } from 'react-toastify';
import { LlmFileExtractor } from '../components/llm-file-extractor';
import { useFeatureFlagEnabled } from 'posthog-js/react';

export default function BillForm({ ...props }) {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [isVendorSelected, setIsVendorSelected] = useState(false);
  const [values, setValues] = useState();
  const params = useParams();
  const navigate = useNavigate();
  const id = params.id;
  const useLLMExtraction = useFeatureFlagEnabled('bill_llm_extraction');
  const buildrfiFinancingEnabled = useFeatureFlagEnabled('buildrfi_financing');
  const [extraBodyData, setExtraBodyData] = useState({});

  const [billPreviewUrl, setBillPreviewUrl] = useState(null);

  useEffect(() => {
    if (values?.document?.key) {
      console.log('Bill doc key found, fetching preview URL...');
      api(`${process.env.REACT_APP_API_URL}/document/download-url?key=${values.document.key}`)
        .then((res) => {
          if (res?.url) {
            setBillPreviewUrl(res.url);
          }
        })
        .catch((err) => {
          console.error('Error fetching Bill doc preview URL', err);
        });
    } else {
      setBillPreviewUrl(null);
    }
  }, [values]);

  const llmConfig = {
    invoiceNumber: "the invoice number from the document",
    date: "the order date from the document",
    subtotal: "the subtotal amount listed, formatted as a number",
    tax: "the tax amount listed",
    dueDate: "the due date from the document",
  };

  const deleteProject = () => {
    api_delete(`${process.env.REACT_APP_API_URL}/app/bill/${id}`).then(() =>
      navigate(-1),
    );
  };

  const handleFieldChange = async (key, value, values, setValues) => {
    if (id === value || key !== 'vendorId') return;
    if (value === ADD_VENDOR_OPTION_ID) {
      setIsVendorSelected(true);
      setValues({
        ...values,
        vendorId: '',
        vendorName: '',
        vendorContactFirstName: '',
        vendorContactLastName: '',
        vendorContactEmail: '',
        vendorContactPhoneNumber: '',
        vendorContactCompanyAddress: '',
      });
      return;
    }

    setIsVendorSelected(true); // Set vendor selected to true

    setValues({
      ...values,
      vendorId: '',
      vendorName: '',
      vendorContactFirstName: '',
      vendorContactLastName: '',
      vendorContactEmail: '',
      vendorContactPhoneNumber: '',
      vendorContactCompanyAddress: '',
    });

    const selectedVendorInfo = await api(
      `${process.env.REACT_APP_API_URL}/app/vendor/${value}`,
    );

    setValues({
      ...values,
      vendorId: value,
      vendorName: selectedVendorInfo.name,
      vendorContactFirstName: selectedVendorInfo.firstName,
      vendorContactLastName: selectedVendorInfo.lastName,
      vendorContactEmail: selectedVendorInfo.email,
      vendorContactCompanyAddress: selectedVendorInfo.companyAddress,
      vendorContactPhoneNumber: selectedVendorInfo.phoneNumber,
    });
  };

  useEffect(() => {
    if (!props.noId && id && id !== 'new') {
      api(`${process.env.REACT_APP_API_URL}/app/bill/${id}`).then((x) => {
        setValues(x ?? {});
        if (x && x.vendorName) {
          setIsVendorSelected(true);
        }
      });
    }
  }, [id]);

  async function handleFormSubmit(values) {
    if (values?.document) {
      await api_update(
        `${process.env.REACT_APP_API_URL}/document/${values?.document}`,
        { source: 'bill', sourceId: values._id },
      );
    }
    const path = '/bill/' + values._id;
    navigate(path);
    toast.success('Bill created successfully');
  }

  const formatDate = (date) => {
    if (!date || !Date.parse(date)) {
      return null;
    }
    return new Date(date).toISOString().split("T")[0];
  };

  return (
    <div className="sm:px-4 relative">
      <Confirm
        open={confirmDelete}
        confirm={() => deleteProject()}
        cancel={() => setConfirmDelete(false)}
      ></Confirm>
      <FormWrapper
        url="app/bill"
        id={id}
        {...props}
        callback={props.callback || (async (values) => handleFormSubmit(values))}
        validate={() => true}
        extraBodyData={extraBodyData}
      >
        {(values, setValues) => (
          <>
            {values?._id &&
              <div className={'!absolute -top-2 -right-2 flex flex-row items-center justify-center bg-secondary-200 p-2 rounded-md'}>

                {buildrfiFinancingEnabled &&
                  <>
                    <p className="mr-4">
                      Financing:
                    </p>
                    <PayLaterButton
                      bill={values}
                      preload={true}
                      className={'animate-tada'}
                    /></>}
                <CheckboxField
                  label="Mark as paid"
                  value={values.paid}
                  onChange={(val) => setValues({ ...values, paid: val })}
                  className={'ml-4 !mt-0'}
                  inline={true}
                />

              </div>
            }
            <div className="text-3xl font-bold tracking-tighter text-gray-800">
              Bill Details
            </div>

            <div className="my-4">
              {useLLMExtraction ? (
                <LlmFileExtractor
                  config={llmConfig}
                  multiple={false}
                  hideTitle={false}
                  extraDocumentData={{ "phoneNumber": "the phone number of the customer" }}
                  onExtracted={async (data) => {
                    setValues((prevValues) => ({
                      ...prevValues,
                      invoiceNumber: data.invoiceNumber ?? prevValues.invoiceNumber,
                      orderDate: data.date ? formatDate(data.date) : prevValues.orderDate,
                      subtotal: data.subtotal ?? prevValues.subtotal,
                      tax: data.tax ?? prevValues.tax,
                      dueDate: data.dueDate ? formatDate(data.dueDate) : prevValues.dueDate,
                    }));
                    setExtraBodyData({ extraDocumentData: data.extraDocumentData });
                  }}

                  filename={values.invoiceNumber + ' bill'}
                  value={values.document}
                  onChange={(val) =>
                    setValues({ ...values, document: val })
                  }
                  className="w-full"
                  source="bill"
                  required
                />
              ) : (
                <FileField
                  filename={values.invoiceNumber + ' bill'}
                  value={values.document}
                  onChange={(val) =>
                    setValues({ ...values, document: val, source: 'bill' })
                  }
                  className="w-full"
                  source="bill"
                  required
                />
              )}
            </div>

            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
              <SelectField
                optionListName="projects"
                label="Project"
                value={values.project}
                onChange={(val) => setValues({ ...values, project: val })}
              />
              <TextField
                label="Order Number"
                value={values.invoiceNumber}
                onChange={(val) => setValues({ ...values, invoiceNumber: val })}
                required
              />
            </div>

            <div className="grid grid-cols-1 gap-4 sm:grid-cols-4">
              <CurrencyField
                label="Subtotal"
                value={values.subtotal}
                onChange={(val) => setValues({ ...values, subtotal: val })}
                min={0}
              />
              <div className='flex items-start justify-start relative w-full gap-1'>
                {!values.taxExempt && (
                  <CurrencyField
                    label="Tax"
                    value={values.tax}
                    onChange={(val) => setValues({ ...values, tax: val })}
                    min={0}
                    className={`w-2/3`}
                    required={true}
                  />
                )}
                {values.taxExempt && <div className='w-2/3'></div>}
                <CheckboxField
                  label="Tax Exempt?"
                  value={values.taxExempt}
                  onChange={(val) =>
                    setValues({
                      ...values,
                      taxExempt: val,
                      tax: val ? 0 : values.tax,
                    })
                  }
                  labelClass={'text-xs'}
                  className={`w-1/3`}
                ></CheckboxField>
              </div>


              <DateField
                label="Order Date"
                value={values.orderDate}
                onChange={(val) => setValues({ ...values, orderDate: val })}
              />
              <DateField
                label="Due Date"
                value={values.dueDate}
                onChange={(val) => setValues({ ...values, dueDate: val })}
              />

            </div>

            <div className="mt-10 mb-2 text-3xl font-bold tracking-tighter text-gray-800">
              Vendor Details
            </div>
            <div className="grid grid-cols-1 gap-4 mb-7 sm:grid-cols-2">
              <SelectField
                label="Find your vendor"
                value={values.vendorId}
                optionListName="vendors"
                required={!isVendorSelected}
                onChange={(val) =>
                  handleFieldChange('vendorId', val, values, setValues)
                }
              />
            </div>

            {isVendorSelected && (
              <>
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
                  <TextField
                    label="Vendor Name"
                    value={values.vendorName}
                    onChange={(val) =>
                      setValues({ ...values, vendorName: val })
                    }
                    required
                  />
                  <TextField
                    label="Vendor Contact First Name"
                    value={values.vendorContactFirstName}
                    onChange={(val) =>
                      setValues({ ...values, vendorContactFirstName: val })
                    }
                    required
                  />
                  <TextField
                    label="Vendor Contact Last Name"
                    value={values.vendorContactLastName}
                    onChange={(val) =>
                      setValues({ ...values, vendorContactLastName: val })
                    }
                    required
                  />
                </div>

                <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
                  <TextField
                    label="Vendor Contact Email"
                    value={values.vendorContactEmail}
                    onChange={(val) =>
                      setValues({ ...values, vendorContactEmail: val })
                    }
                    required
                  />
                  <PhoneField
                    label="Vendor Contact Phone Number"
                    value={values.vendorContactPhoneNumber}
                    onChange={(val) =>
                      setValues({ ...values, vendorContactPhoneNumber: val })
                    }
                    required
                  />
                  <TextField
                    label="Vendor Contact Company Address"
                    value={values.vendorContactCompanyAddress}
                    onChange={(val) =>
                      setValues({ ...values, vendorContactCompanyAddress: val })
                    }
                    required
                  />
                </div>
              </>
            )}

            {id !== 'new' && (
              <div className="relative">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setConfirmDelete(true);
                  }}
                  className="absolute top-0 left-0 inline-flex items-center justify-center px-4 py-2 mt-5 text-sm font-medium text-white border border-transparent rounded shadow-sm cursor-pointer hover-lift bg-rose-500 hover:bg-rose-600 focus:outline-none focus:ring-2 focus:ring-rose-500 focus:ring-offset-2"
                >
                  Delete
                </button>
              </div>
            )}
          </>
        )}
      </FormWrapper>
    </div>
  );
}
