import React, { useState, useEffect } from 'react';
import FileField from '../forms/fields/file-field';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import Modal from './modal';
import { api_post, api } from '../api.service';
import { Cog6ToothIcon, SparklesIcon } from '@heroicons/react/24/outline';
import Loading from './loading';
import { toast } from 'react-toastify';
import FilePreviewWithMagnification from './file-preview-with-magnification';

/**
 * LlmFileExtractor:
 * - Renders a FileField for uploading a PDF, plus an AI extraction workflow.
 * - Displays a side-by-side layout on larger screens:
 *   Left: Document preview
 *   Right: Extraction controls (FileField + config editor).
 * - On small screens, it's a stacked layout (preview on top, extractor below).
 */
export const LlmFileExtractor = ({
  config,
  extraDocumentData,
  onExtracted,
  onChange,
  hideTitle = false,
  label,
  value,
  className,
  labelClass,
  source,
  disabled = false,
  required = false
}) => {
  // States
  const [processing, setProcessing] = useState(false);
  const [showSuccessBanner, setShowSuccessBanner] = useState(false);

  const [extractedData, setExtractedData] = useState(null);

  // For editing instructions if feature is toggled
  const editVariablesEnabled = useFeatureFlagEnabled('internal_extraction_tools');
  const [showModal, setShowModal] = useState(false);

  // Local copies of config and extraDocumentData
  const [editableConfig, setEditableConfig] = useState({});
  const [editableExtraDocumentData, setEditableExtraDocumentData] = useState({});

  const [docKey, setDocKey] = useState(null);
  const [documentUrl, setDocumentUrl] = useState(null);

  useEffect(() => {
    setEditableConfig({ ...config });
  }, [config]);

  useEffect(() => {
    setEditableExtraDocumentData({ ...extraDocumentData });
  }, [extraDocumentData]);

  useEffect(() => {
    if (value && value.key) {
      setDocKey(value.key);
    }
  }, [value]);

  useEffect(() => {
    if (!docKey) {
      setDocumentUrl(null);
      return;
    }
    api(`${process.env.REACT_APP_API_URL}/document/download-url?key=${docKey}`)
      .then(res => {
        if (res?.url) {
          setDocumentUrl(res.url);
        }
      })
      .catch(err => {
        console.error('Error fetching doc preview URL:', err);
        setDocumentUrl(null);
      });
  }, [docKey]);

  async function handleFileChange(newDoc) {
    if (!newDoc || !newDoc.key) {
      setDocKey(null);
      return;
    }
    setDocKey(newDoc.key);

    if (typeof onChange === 'function') {
      onChange(newDoc._id);
    }

    if (!newDoc.file) return; // only run extraction for newly uploaded files

    setProcessing(true);
    try {
      const pdfBase64 = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const dataUrl = e.target.result;
          const base64 = dataUrl.split(',')[1];
          resolve(base64);
        };
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(newDoc.file);
      });

      // Make AI extraction request
      const requestBody = {
        pdfBase64,
        config: editableConfig,
        extraDocumentData: editableExtraDocumentData,
      };
      const extraction = await api_post(
        `${process.env.REACT_APP_API_URL}/app/extract-variables`,
        requestBody
      );

      if (extraction?.error) {
        throw new Error(extraction.error);
      }

      setExtractedData(extraction);

      // Fire onExtracted
      if (typeof onExtracted === 'function') {
        await onExtracted(extraction);
      }

      // Show success banner
      setShowSuccessBanner(true);
      setTimeout(() => {
        setShowSuccessBanner(false);
      }, 3000);

    } catch (error) {
      console.error('Extraction failed:', error);
      toast.error('Could not extract data; please enter fields manually.');
    } finally {
      setProcessing(false);
    }
  }

  // Config editor
  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const handleSaveConfig = () => setShowModal(false);

  return (
    <div className={className || ''}>

      {/* Overlays: processing banner */}
      {processing && (
        <div className="absolute inset-0 backdrop-blur-sm flex flex-col items-center justify-center z-50 rounded-lg overflow-hidden">
          <div className="absolute inset-0 bg-gray-500 opacity-70"></div>
          <div className="flex flex-row items-center justify-center relative z-10 space-x-4 p-6">
            <SparklesIcon className="w-12 h-12 text-white animate-pulse" />
            <div className="text-white text-lg font-semibold">
              Uploading file and extracting data with AI...
            </div>
            <div className="w-12 h-12">
              <Loading />
            </div>
          </div>
        </div>
      )}

      {/* Overlays: success banner */}
      {showSuccessBanner && (
        <div className="absolute inset-0 backdrop-blur-sm flex flex-col items-center justify-center z-50 rounded-lg overflow-hidden">
          <div className="absolute inset-0 bg-gray-500 opacity-70"></div>
          <div className="flex flex-row items-center justify-center relative z-10 space-x-2 p-6">
            <SparklesIcon className="w-12 h-12 text-white animate-pulse" />
            <div className="text-white text-lg font-semibold">
              Success! Data extracted from file.
            </div>
          </div>
        </div>
      )}

      {/* Two-column (stacked on mobile) */}
      <div className="flex flex-col md:flex-row gap-4">
        {/* Left: File preview */}
        <div className="w-full md:w-1/5">
          <FilePreviewWithMagnification
            fileUrl={documentUrl}
            placeholderText="No file to preview"
            previewLabel="Preview (click to enlarge)"
            docName={docKey}
            className="h-full"
          />
        </div>

        {/* Right: FileField + (optionally) config editor button */}
        <div className="w-full space-y-4">
          <FileField
            label={label}
            value={value}
            onChange={handleFileChange}
            className="max-w-full"
            labelClass={labelClass}
            source={source}
            hideNote={hideTitle}
            disabled={disabled || processing}
            required={required}
            returnFullDoc={true}
            icon={<SparklesIcon className="w-6 h-6 mr-2 -ml-6" />}
          />

          {editVariablesEnabled && (
            <div>
              <button
                onClick={handleOpenModal}
                type="button"
                className="inline-flex items-center px-3 py-2 text-sm font-medium text-gray-700 bg-gray-200 rounded hover:bg-gray-300 transition-colors"
              >
                <Cog6ToothIcon className="w-4 h-4 mr-1" />
                Manage Extraction Config
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Config Editor Modal */}
      {showModal && (
        <Modal onClose={handleCloseModal} darken containerClassName="max-w-md w-full">
          <h2 className="text-xl font-semibold mb-4">Edit Extraction Instructions</h2>
          <p className="text-sm text-gray-700 mb-2">
            Adjust instructions for each variable. These guide the LLM on what to extract.
          </p>

          {/* Config editing */}
          <h3 className="text-md font-semibold mb-2">Config Instructions</h3>
          <div className="flex flex-col gap-2 max-h-36 overflow-auto mb-4">
            {Object.keys(editableConfig).map((key) => (
              <div key={key} className="flex flex-col">
                <label className="text-sm font-medium text-gray-700">
                  {key}
                </label>
                <input
                  type="text"
                  className="border rounded p-1"
                  value={editableConfig[key] ?? ''}
                  onChange={(e) =>
                    setEditableConfig({ ...editableConfig, [key]: e.target.value })
                  }
                />
              </div>
            ))}
          </div>

          {/* Extra Document Data Editing */}
          {Object.keys(editableExtraDocumentData).length > 0 && (
            <>
              <h3 className="text-md font-semibold mb-2">Extra Document Data</h3>
              <div className="flex flex-col gap-2 max-h-36 overflow-auto mb-4">
                {Object.keys(editableExtraDocumentData).map((key) => (
                  <div key={key} className="flex flex-col">
                    <label className="text-sm font-medium text-gray-700">
                      {key}
                    </label>
                    <input
                      type="text"
                      className="border rounded p-1"
                      value={editableExtraDocumentData[key] ?? ''}
                      onChange={(e) =>
                        setEditableExtraDocumentData({
                          ...editableExtraDocumentData,
                          [key]: e.target.value
                        })
                      }
                    />
                  </div>
                ))}
              </div>
            </>
          )}

          <div className="flex justify-end mt-4 gap-2">
            <button
              onClick={handleSaveConfig}
              className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded hover:bg-blue-700"
            >
              Save
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};