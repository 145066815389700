import React, { useEffect, useState } from "react";
import Modal from "./modal";
import { api } from "../api.service";
import { use } from "react";

/**
 * A single-file preview with magnification.
 * - If fileUrl ends with .pdf => we call /document/view-url?key= to fetch an inline PDF presigned URL,
 *   then show an <iframe> for both thumbnail and modal views.
 * - Otherwise show an <img> preview, open a larger modal on click.
 *
 * @param {string} fileUrl - The raw file link or S3 key. If it's a PDF or includes ".pdf", we treat it as a PDF key.
 * @param {string} [placeholderText] - Optional text to display if no file
 * @param {string} [previewLabel] - Label text for the smaller preview area
 */
export default function FilePreviewWithMagnification({
  fileUrl,
  placeholderText = "No file to preview",
  previewLabel = "File Preview (click to enlarge)",
  docName,
  className
}) {
  const [showModal, setShowModal] = useState(false);
  const [resolvedUrl, setResolvedUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [docKey, setDocKey] = useState(null);

  useEffect(() => {
    if (docName) {
      setDocKey(docName);
    }
  }, [docName]);

  // Check if it's presumably a PDF
  const isPdf = fileUrl?.toLowerCase()?.includes(".pdf");

  // For PDFs, call /document/view-url?key=...
  // For images or other files, we assume fileUrl is a direct link
  useEffect(() => {
    if (!isPdf) {
      // Not a PDF => treat as direct link
      setResolvedUrl(fileUrl);
      return;
    }

    // It's a PDF => fetch from /document/view-url
    setIsLoading(true);
    api(`${process.env.REACT_APP_API_URL}/document/view-url?key=${docKey}`)
      .then((res) => {
        if (res?.url) {
          setResolvedUrl(res.url);
        }
      })
      .catch((err) => {
        console.error("Error fetching inline PDF URL:", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [fileUrl, isPdf, docKey]);

  // If no fileUrl passed, show placeholder
  if (!fileUrl) {
    return (
      <div className="w-full h-32 bg-primary rounded border flex items-center justify-center text-gray-400 text-sm">
        {placeholderText}
      </div>
    );
  }



  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  // If isPdf but we’re waiting on the /view-url endpoint
  if (isPdf && isLoading && !resolvedUrl) {
    return (
      <div className="w-full h-32 bg-primary rounded border flex items-center justify-center text-gray-500 text-sm">
        Loading PDF...
      </div>
    );
  }

  // If isPdf but we have no resolvedUrl (error or empty)
  if (isPdf && !resolvedUrl) {
    return (
      <div className="w-full h-32 bg-primary rounded border flex items-center justify-center text-red-500 text-sm">
        Could not load PDF
      </div>
    );
  }

  return (
    <div className="w-full p-2 border rounded-lg">
      {/* Thumbnail Preview */}
      <div
        className={`cursor-pointer w-full h-32 bg-white border rounded-lg flex items-center justify-center text-gray-700 relative ${className}`}
        onClick={handleOpenModal}
      >
        {isPdf ? (
          <>
            <iframe
              src={resolvedUrl}
              title="PDF Thumbnail Preview"
              className="w-full h-full relative z-0"
            />
            <div className="absolute w-full h-full" onClick={()=>handleOpenModal()} />
          </>
        ) : (
          <img
            src={resolvedUrl}
            alt="preview"
            className="object-contain h-full"
          />
        )}
      </div>

      {/* Magnified Preview Modal */}
      {showModal && (
        <Modal
          onClose={handleCloseModal}
          blur
          darken
          containerClassName="w-full max-w-6xl h-full max-h-[90vh] overflow-y-auto"
        >
          <div className="relative w-full h-full mx-auto bg-white flex items-center justify-center">
            {isPdf ? (
              <iframe
                src={resolvedUrl}
                title="PDF Preview"
                className="w-full h-full border"
              />
            ) : (
              <img
                src={resolvedUrl}
                alt="full preview"
                className="max-w-full max-h-full"
              />
            )}
          </div>
        </Modal>
      )}
    </div>
  );
}