import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { api, api_post, api_update } from '../api.service';
import { toast, ToastContainer } from 'react-toastify';
import Loading from '../components/loading';
import PageHeader from '../components/page-header';
import { getStatusClass } from '../utils';
import { BanknotesIcon } from '@heroicons/react/24/outline';
import {
    BriefcaseIcon,
    ClipboardDocumentListIcon,
    DocumentTextIcon,
    UserIcon,
    CreditCardIcon
} from '@heroicons/react/24/outline';

/**
 * Main Component: FinancingRequestDetail
 */
const FinancingRequestDetail = () => {
    const { id } = useParams();
    const [financingRequest, setFinancingRequest] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [activeTab, setActiveTab] = useState('Business');
    const [creditApplication, setCreditApplication] = useState(null);

    useEffect(() => {
        fetchFinancingRequest();
    }, [id]);

    const fetchFinancingRequest = async () => {
        setLoading(true);
        try {
            const result = await api(
                `${process.env.REACT_APP_API_URL}/admin/financing-request/${id}`
            );
            // Store at least an empty object to avoid null
            setFinancingRequest(result || {});

            // Attempt to fetch credit application only if business._id is available
            if (result && result?.business && result.business?._id) {
                const creditApplicationResult = await api(
                    `${process.env.REACT_APP_API_URL}/credit-application/business/${result.business._id}`
                );
                setCreditApplication(creditApplicationResult[0] || null);
            } else {
                setCreditApplication(null);
            }
        } catch (err) {
            setError('Error loading financing request data');
            console.error(err);
            toast.error('Failed to load financing request details');
        } finally {
            setLoading(false);
        }
    };
    
    const handleApprove = async () => {
        try {
            const updatedFinancingRequest = {
                ...financingRequest,
                status: 'Approved'
            };
            await updateFinancingRequest(updatedFinancingRequest);
            toast.success('Financing request approved');
            setFinancingRequest(updatedFinancingRequest);
        } catch (err) {
            toast.error('Failed to approve financing request');
            console.error(err);
        }
    };

    const handleReject = async () => {
        try {
            const updatedFinancingRequest = {
                ...financingRequest,
                status: 'Rejected'
            };
            await updateFinancingRequest(updatedFinancingRequest);
            toast.success('Financing request rejected');
            setFinancingRequest(updatedFinancingRequest);
        } catch (err) {
            toast.error('Failed to reject financing request');
            console.error(err);
        }
    };

    const updateFinancingRequest = async (updatedFinancingRequest) => {
        try {
            await api_update(
                `${process.env.REACT_APP_API_URL}/admin/financing-request/${id}`,
                updatedFinancingRequest
            );
            setFinancingRequest(updatedFinancingRequest);
            toast.success('Financing request updated successfully');
        } catch (err) {
            toast.error('Failed to update financing request');
            console.error(err);
        }
    };

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return <div className="text-red-600">{error}</div>;
    }

    // If we still don't have a valid financingRequest
    if (!financingRequest || (!financingRequest._id && !financingRequest.status)) {
        return <div>No Financing Request Found</div>;
    }

    // Destructure with fallback objects/values to avoid null references
    const {
        invoice = {},
        customer = {},
        business = {},
        achInfo = {},
        status = 'Unknown',
        amount = 0,
        product = 'Unknown',
        createdAt,
        updatedAt,
        project = {},
        offers = [],
        plaidAccounts = []
    } = financingRequest;

    const loanSnapshot = {
        financingRequest: {
            id: financingRequest._id,
            status: financingRequest.status,
            invoice: financingRequest.invoice,
            customer: financingRequest.customer,
            business: financingRequest.business,
            achInfo: financingRequest.achInfo,
            amount: financingRequest.amount,
            product: financingRequest.product,
            createdAt: financingRequest.createdAt,
            updatedAt: financingRequest.updatedAt,
            project: financingRequest.project,
            offers: financingRequest.offers,
            plaidAccounts: financingRequest.plaidAccounts
        },
        creditApplication: {
            id: creditApplication?._id || null,
            status: creditApplication?.status || null,
            createdAt: creditApplication?.createdAt || null,
            updatedAt: creditApplication?.updatedAt || null,
            applicationData: creditApplication?.applicationData || null
        },
        business: {
            id: financingRequest.business?._id,
            name: financingRequest.business?.name,
            type: financingRequest.business?.type
        },
        snapshotCreatedAt: new Date().toISOString()
    };

    const renderValue = (value, isCurrency = false) => {
        if (value !== undefined && value !== null) {
            return isCurrency ? `$${value}` : value;
        }
        return <span className="text-yellow-500">Missing</span>;
    };

    return (
        <div className="flex flex-col pb-36">
            <ToastContainer position="top-right" autoClose={2000} />
            <PageHeader headline="Financing Request Detail" showBackButton="true" />

            <div className="container mx-auto my-10">
                <div className="mx-6">
                    {/* Financing Request Info */}
                    <div className="bg-primary shadow-sm border rounded-lg p-6 mb-8">
                        <h2 className="text-3xl font-medium mb-4 flex items-center">
                            <BanknotesIcon className="w-8 h-8 mr-2" />
                            Financing Request
                        </h2>
                        <div className="grid gap-y-2 border rounded-md">
                            <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
                                <span className="font-medium">Invoice ID:</span>
                                <span>{renderValue(invoice._id)}</span>
                            </div>
                            <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
                                <span className="font-medium">Amount:</span>
                                <span>{renderValue(amount, true)}</span>
                            </div>
                            <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
                                <span className="font-medium">Product:</span>
                                <span>{renderValue(product)}</span>
                            </div>
                            <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
                                <span className="font-medium">Status:</span>
                                <span className={`${getStatusClass(status)} capitalize`}>
                                    {status}
                                </span>
                            </div>
                            <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
                                <span className="font-medium">Created At:</span>
                                <span>
                                    {createdAt ? new Date(createdAt).toLocaleString() : 'N/A'}
                                </span>
                            </div>
                            <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
                                <span className="font-medium">Updated At:</span>
                                <span>
                                    {updatedAt ? new Date(updatedAt).toLocaleString() : 'N/A'}
                                </span>
                            </div>
                        </div>
                    </div>

                    {/* Tabs */}
                    <div className="mb-4">
                        <div className="flex gap-x-4">
                            {['Business', 'Project', 'Customer', 'ACH Info', 'Credit Application'].map((tab) => (
                                <button
                                    key={tab}
                                    className={`px-6 py-2 font-semibold hover:scale-105 transition-all border rounded-lg ${activeTab === tab ? 'bg-primary shadow-sm' : 'text-gray-700'
                                        }`}
                                    onClick={() => setActiveTab(tab)}
                                >
                                    {tab}
                                </button>
                            ))}
                            {product === 'Pay Later' && (
                                <button
                                    key="Bill"
                                    className={`px-6 py-2 font-semibold hover:scale-105 transition-all border rounded-lg ${activeTab === 'Bill' ? 'bg-primary shadow-sm' : 'text-gray-700'
                                        }`}
                                    onClick={() => setActiveTab('Bill')}
                                >
                                    Bill
                                </button>
                            )}
                            {product === 'Fund Now' && (
                                <button
                                    key="Invoice"
                                    className={`px-4 py-2 -mb-px font-semibold ${activeTab === 'Invoice'
                                        ? 'border-t border-l border-r rounded-t text-blue-700'
                                        : 'text-gray-700'
                                        }`}
                                    onClick={() => setActiveTab('Invoice')}
                                >
                                    Invoice
                                </button>
                            )}
                            {plaidAccounts.length > 0 && (
                                <button
                                    key="Plaid"
                                    className={`px-6 py-2 font-semibold hover:scale-105 transition-all border rounded-lg ${activeTab === 'Plaid' ? 'bg-primary shadow-sm' : 'text-gray-700'
                                        }`}
                                    onClick={() => setActiveTab('Plaid')}
                                >
                                    Plaid
                                </button>
                            )}
                        </div>
                    </div>

                    {/* Tab Content */}
                    <div className="mb-8">
                        {activeTab === 'Business' && <BusinessComponent business={business} />}
                        {activeTab === 'Project' && <ProjectComponent project={project} />}
                        {activeTab === 'Customer' && <CustomerComponent customer={customer} />}
                        {activeTab === 'Bill' && <BillsComponent bill={invoice} />}
                        {activeTab === 'Invoice' && <InvoicesComponent invoice={invoice} />}
                        {activeTab === 'ACH Info' && <ACHInfoComponent achInfo={achInfo} />}
                        {activeTab === 'Credit Application' && (
                            <CreditApplicationComponent creditApplication={creditApplication || {}} />
                        )}
                        {activeTab === 'Plaid' && (
                            <PlaidComponent plaidAccounts={plaidAccounts} />
                        )}
                    </div>

                    {/* Offers Section */}
                    <div className="bg-primary shadow-sm border rounded-lg p-6 mb-8">
                        <OffersSection
                            financingRequest={financingRequest}
                            updateFinancingRequest={updateFinancingRequest}
                            billId={invoice._id || ''}
                            loanSnapshot={loanSnapshot}
                        />
                    </div>

                    {/* Approve and Reject Buttons */}
                    <div className="mt-6 flex space-x-4">
                        <button
                            onClick={handleReject}
                            className="bg-red-400 hover:bg-red-600 text-white font-medium py-2 px-4 rounded transform hover:scale-105 transition-transform"
                        >
                            Reject
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

/**
 * BusinessComponent
 */
const BusinessComponent = ({ business = {} }) => {
    const fields = [
        { label: 'Business ID', value: business?._id },
        { label: 'Name', value: business?.name },
        { label: 'Type', value: business?.type }
    ];

    return (
        <div className="bg-primary shadow-sm border rounded-lg p-6 mb-8">
            <h2 className="text-3xl font-medium mb-4 flex items-center">
                <BriefcaseIcon className="w-8 h-8 mr-2" />
                Business Details
            </h2>
            <div className="grid gap-y-2 border rounded-md">
                {fields.map((field, index) => (
                    <div
                        key={index}
                        className={`grid grid-cols-2 gap-x-4 py-2 px-4 ${index % 2 === 0 ? 'bg-gray-100' : ''
                            }`}
                    >
                        <span className="font-medium">{field.label}:</span>
                        <span>
                            {field.value !== undefined && field.value !== null
                                ? field.value
                                : <span className="text-yellow-500">Missing</span>}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
};

/**
 * ProjectComponent
 */
const ProjectComponent = ({ project = {} }) => {
    const fields = [
        { label: 'Project ID', value: project?._id },
        { label: 'Name', value: project?.name },
        { label: 'Project Owner', value: project?.projectOwner },
        { label: 'Main Contact', value: project?.mainContact },
        { label: 'Main Contact Email', value: project?.mainContactEmail },
        { label: 'Owner ID', value: project?.owner },
        { label: 'Document ID', value: project?.document }
    ];

    return (
        <div className="bg-primary shadow-sm border rounded-lg p-6 mb-8">
            <h2 className="text-3xl font-medium mb-4 flex items-center">
                <ClipboardDocumentListIcon className="w-8 h-8 mr-2" />
                Project Details
            </h2>
            <div className="grid gap-y-2 border rounded-md">
                {fields.map((field, index) => (
                    <div
                        key={index}
                        className={`grid grid-cols-2 gap-x-4 py-2 px-4 ${index % 2 === 0 ? 'bg-gray-100' : ''
                            }`}
                    >
                        <span className="font-medium">{field.label}:</span>
                        <span>
                            {field.value !== undefined && field.value !== null
                                ? field.value
                                : <span className="text-yellow-500">Missing</span>}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
};

/**
 * CustomerComponent
 */
const CustomerComponent = ({ customer = {} }) => {
    const fields = [
        { label: 'Customer ID', value: customer?._id },
        { label: 'Name', value: customer?.name },
        { label: 'Email', value: customer?.email },
        { label: 'Business ID', value: customer?.business },
        { label: 'Roles', value: customer?.roles },
        { label: 'Has Free Access', value: customer?.hasFreeAccess ? 'Yes' : 'No' }
    ];

    return (
        <div className="bg-primary shadow-sm border rounded-lg p-6 mb-8">
            <h2 className="text-3xl font-medium mb-4 flex items-center">
                <UserIcon className="w-8 h-8 mr-2" />
                Customer Details
            </h2>
            <div className="grid gap-y-2 border rounded-md">
                {fields.map((field, index) => (
                    <div
                        key={index}
                        className={`grid grid-cols-2 gap-x-4 py-2 px-4 ${index % 2 === 0 ? 'bg-gray-100' : ''
                            }`}
                    >
                        <span className="font-medium">{field.label}:</span>
                        <span>
                            {field.value !== undefined && field.value !== null
                                ? field.value
                                : <span className="text-yellow-500">Missing</span>}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
};

/**
 * ACHInfoComponent
 */
const ACHInfoComponent = ({ achInfo = {} }) => {
    const fields = [
        { label: 'ACH ID', value: achInfo?._id },
        { label: 'Account Number', value: achInfo?.accountNumber },
        { label: 'Routing Number', value: achInfo?.routingNumber },
        { label: 'Bank Name', value: achInfo?.bankName },
        { label: 'Account Type', value: achInfo?.accountType }
    ];

    return (
        <div className="bg-primary shadow-sm border rounded-lg p-6 mb-8">
            <h2 className="text-3xl font-medium mb-4 flex items-center">
                <CreditCardIcon className="w-8 h-8 mr-2" />
                ACH Information
            </h2>
            <div className="grid gap-y-2 border rounded-md">
                {fields.map((field, index) => (
                    <div
                        key={index}
                        className={`grid grid-cols-2 gap-x-4 py-2 px-4 ${index % 2 === 0 ? 'bg-gray-100' : ''
                            }`}
                    >
                        <span className="font-medium">{field.label}:</span>
                        <span>
                            {field.value !== undefined && field.value !== null && field.value !== ''
                                ? field.value
                                : <span className="text-yellow-500">Missing</span>}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
};

/**
 * BillsComponent
 */
const BillsComponent = ({ bill = {} }) => {
    const fields = [
        { label: 'Bill ID', value: bill?._id },
        { label: 'Invoice Number', value: bill?.invoiceNumber },
        { label: 'Vendor Name', value: bill?.vendorName },
        { label: 'Vendor Contact First Name', value: bill?.vendorContactFirstName },
        { label: 'Vendor Contact Last Name', value: bill?.vendorContactLastName },
        { label: 'Vendor Contact Email', value: bill?.vendorContactEmail },
        { label: 'Vendor Contact Phone Number', value: bill?.vendorContactPhoneNumber },
        { label: 'Vendor Contact Company Address', value: bill?.vendorContactCompanyAddress },
        { label: 'Subtotal', value: bill?.subtotal },
        { label: 'Tax', value: bill?.tax },
        {
            label: 'Due Date',
            value: bill?.dueDate ? new Date(bill?.dueDate).toLocaleDateString() : null
        },
        {
            label: 'Order Date',
            value: bill?.orderDate ? new Date(bill?.orderDate).toLocaleDateString() : null
        },
        {
            label: 'Date Uploaded',
            value: bill?.dateUploaded ? new Date(bill?.dateUploaded).toLocaleString() : null
        }
    ];

    return (
        <div className="bg-primary shadow-sm border rounded-lg p-6 mb-8">
            <h2 className="text-3xl font-medium mb-4 flex items-center">
                <DocumentTextIcon className="w-8 h-8 mr-2" />
                Bill Details
            </h2>
            <div className="grid gap-y-2 border rounded-md">
                {fields.map((field, index) => (
                    <div
                        key={index}
                        className={`grid grid-cols-2 gap-x-4 py-2 px-4 ${index % 2 === 0 ? 'bg-gray-100' : ''
                            }`}
                    >
                        <span className="font-medium">{field.label}:</span>
                        <span>
                            {field.value !== undefined && field.value !== null
                                ? field.value
                                : <span className="text-yellow-500">Missing</span>}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
};

/**
 * InvoicesComponent
 */
const InvoicesComponent = ({ invoice = {} }) => {
    const fields = [
        { label: 'Invoice ID', value: invoice?._id },
        { label: 'Invoice Number', value: invoice?.invoiceNumber },
        { label: 'Vendor Name', value: invoice?.vendorName },
        { label: 'Vendor Contact First Name', value: invoice?.vendorContactFirstName },
        { label: 'Vendor Contact Last Name', value: invoice?.vendorContactLastName },
        { label: 'Vendor Contact Email', value: invoice?.vendorContactEmail },
        { label: 'Vendor Contact Phone Number', value: invoice?.vendorContactPhoneNumber },
        { label: 'Vendor Contact Company Address', value: invoice?.vendorContactCompanyAddress },
        { label: 'Subtotal', value: invoice?.subtotal },
        { label: 'Tax', value: invoice?.tax },
        {
            label: 'Due Date',
            value: invoice?.dueDate ? new Date(invoice?.dueDate).toLocaleDateString() : null
        },
        {
            label: 'Order Date',
            value: invoice?.orderDate ? new Date(invoice?.orderDate).toLocaleDateString() : null
        },
        {
            label: 'Date Uploaded',
            value: invoice?.dateUploaded ? new Date(invoice?.dateUploaded).toLocaleString() : null
        }
    ];

    return (
        <div className="bg-primary shadow-sm border rounded-lg p-6 mb-8">
            <h2 className="text-3xl font-medium mb-4 flex items-center">
                <DocumentTextIcon className="w-8 h-8 mr-2" />
                Invoice Details
            </h2>
            <div className="grid gap-y-2 border rounded-md">
                {fields.map((field, index) => (
                    <div
                        key={index}
                        className={`grid grid-cols-2 gap-x-4 py-2 px-4 ${index % 2 === 0 ? 'bg-gray-100' : ''
                            }`}
                    >
                        <span className="font-medium">{field.label}:</span>
                        <span>
                            {field.value !== undefined && field.value !== null
                                ? field.value
                                : <span className="text-yellow-500">Missing</span>}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
};

/**
 * CreditApplicationComponent
 */
const CreditApplicationComponent = ({ creditApplication = {} }) => {
    // Fallback so we don't break if applicationData is missing
    const { applicationData = {} } = creditApplication;

    const fields = [
        { label: 'Application ID', value: creditApplication?._id },
        { label: 'Status', value: creditApplication?.status },
        { label: 'Company Legal Name', value: applicationData.companyLegalName },
        { label: 'Company Address', value: applicationData.companyAddress },
        { label: 'Date of Incorporation', value: applicationData.dateOfIncorporation },
        { label: 'EIN Number', value: applicationData.einNumber },
        { label: 'Phone Number', value: applicationData.phoneNumber },
        { label: 'Website', value: applicationData.website },
        { label: 'Types of Projects', value: applicationData.typesOfProjectsYouWorkOn },
        { label: 'Average Project Value Per Month', value: applicationData.averageProjectValuePerMonth },
        { label: 'Monthly Revenue', value: applicationData.monthlyRevenue },
        { label: 'Monthly Debt Payment', value: applicationData.monthlyDebtPayment },
        { label: 'Bank Account File', value: applicationData.bankAccountFile },
        { label: 'QuickBooks File', value: applicationData.quickbooksFile },
        { label: 'Project Management File', value: applicationData.projectManagementFile },
        {
            label: 'Created At',
            value: creditApplication?.createdAt ? new Date(creditApplication.createdAt).toLocaleString() : null
        },
        {
            label: 'Updated At',
            value: creditApplication?.updatedAt ? new Date(creditApplication.updatedAt).toLocaleString() : null
        }
    ];

    return (
        <div className="bg-primary shadow-sm border rounded-lg p-6 mb-8">
            <h2 className="text-3xl font-medium mb-4 flex items-center">
                <DocumentTextIcon className="w-8 h-8 mr-2" />
                Credit Application Details
            </h2>
            <div className="grid gap-y-2 border rounded-md">
                {fields.map((field, index) => (
                    <div
                        key={index}
                        className={`grid grid-cols-2 gap-x-4 py-2 px-4 ${index % 2 === 0 ? 'bg-gray-100' : ''
                            } `}
                    >
                        <span className="font-medium">{field.label}:</span>
                        <span className={field.label === 'Status' ? getStatusClass(field.value) : ''}>
                            {field.value !== undefined && field.value !== null
                                ? field.value
                                : <span className="text-yellow-500">Missing</span>}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
};

/**
 * PlaidComponent
 */
const PlaidComponent = ({ plaidAccounts = [] }) => {
    return (
        <div className="bg-primary shadow-sm border rounded-lg p-6 mb-8">
            <h2 className="text-3xl font-medium mb-4 flex items-center">
                <DocumentTextIcon className="w-8 h-8 mr-2" />
                Plaid Accounts
            </h2>
            {plaidAccounts.length > 0 ? (
                plaidAccounts.map((account, accountIndex) => {
                    const fields = [
                        { label: 'Account ID', value: account?.accountId },
                        { label: 'Official Name', value: account?.officialName },
                        { label: 'Type', value: account?.type },
                        { label: 'Subtype', value: account?.subtype },
                        {
                            label: 'Available Balance',
                            value: account?.balances?.available !== undefined
                                ? `$${account?.balances?.available}`
                                : null
                        },
                        {
                            label: 'Current Balance',
                            value: account?.balances?.current !== undefined
                                ? `$${account?.balances?.current}`
                                : null
                        },
                        {
                            label: 'Limit',
                            value: account?.balances?.limit !== null && account?.balances?.limit !== undefined
                                ? `$${account?.balances?.limit}`
                                : null
                        },
                        {
                            label: 'Currency',
                            value: account?.balances?.currency
                        },
                        {
                            label: 'Created At',
                            value: account?.createdAt ? new Date(account.createdAt).toLocaleString() : null
                        }
                    ];

                    return (
                        <div key={accountIndex} className="mb-8 border rounded-md">
                            <h3 className="text-2xl font-medium mb-4 bg-gray-100 px-4 py-2">
                                Account #{accountIndex + 1}
                                <span className="text-gray-500 font-normal"> - {account?.name || 'N/A'}</span>
                            </h3>
                            {fields.map((field, index) => (
                                <div
                                    key={index}
                                    className={`grid grid-cols-2 gap-x-4 py-2 px-4 ${index % 2 === 0 ? 'bg-gray-100' : ''
                                        }`}
                                >
                                    <span className="font-medium">{field.label}:</span>
                                    <span>
                                        {field.value !== undefined && field.value !== null && field.value !== ''
                                            ? field.value
                                            : <span className="text-yellow-500">Missing</span>}
                                    </span>
                                </div>
                            ))}
                        </div>
                    );
                })
            ) : (
                <div className="text-yellow-500">No Plaid accounts found.</div>
            )}
        </div>
    );
};

const OffersSection = ({
    financingRequest = {},
    updateFinancingRequest,
    billId = '',
    loanSnapshot
}) => {
    // Keep termLength so underwriters can edit it in weeks:
    const [newOffers, setNewOffers] = useState([
        { termLength: '', interestRate: '', amount: '' },
        { termLength: '', interestRate: '', amount: '' },
        { termLength: '', interestRate: '', amount: '' }
    ]);

    const acceptedOffer = financingRequest?.offers?.find(
        (offer) => offer?.status === 'Accepted'
    );

    const isApproved =
        financingRequest?.status === 'Approved' || financingRequest?.approved === true;

    // This is the base "financingRequest.amount" from the DB (bill total)
    const baseAmount = financingRequest?.amount || 0;

    useEffect(() => {
        if (acceptedOffer && isApproved) {
            setNewOffers([acceptedOffer]);
        } else {
            const defaultOffer = { termLength: '', interestRate: '', amount: '' };
            const offers = financingRequest?.offers || [];
            const updatedOffers = [...offers];
            while (updatedOffers.length < 3) {
                updatedOffers.push({ ...defaultOffer });
            }
            // Recalculate amounts in case the interestRate was saved
            const uniqueOffers = updatedOffers.slice(0, 3).map((offer) => {
                const interestRate = parseFloat(offer.interestRate) || 0;
                const computedAmount = baseAmount + baseAmount * (interestRate / 100);
                return {
                    ...offer,
                    amount: offer.termLength && offer.interestRate
                        ? computedAmount.toFixed(2)
                        : offer.amount || '',
                };
            });
            setNewOffers(uniqueOffers);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [financingRequest?.offers, isApproved, acceptedOffer]);

    const handleSaveOffers = async () => {
        try {
            // Validate the fields: ensure termLength is numeric
            // We'll filter out offers that do not have all fields
            const validOffers = newOffers.filter(
                (offer) => offer.termLength && offer.interestRate && offer.amount
            );

            // Double-check numeric constraint for termLength
            for (const offer of validOffers) {
                if (!/^\d+$/.test(offer.termLength)) {
                    toast.error('Term length must be a valid number of weeks.');
                    return;
                }
            }

            const updatedFinancingRequest = {
                ...financingRequest,
                offers: validOffers
            };
            await updateFinancingRequest(updatedFinancingRequest);
            setNewOffers(validOffers);

            toast.success('Offers saved successfully');

            // Example of how to POST the loanSnapshot
            const loanSnapshotString = JSON.stringify(loanSnapshot);
            const response = await api_post(
                `${process.env.REACT_APP_API_URL}/loan-snapshot`,
                { loanSnapshotString }
            );
            if (response && response.status === 'success') {
                toast.success('Loan snapshot saved successfully');
            } else {
                toast.error('Failed to save loan snapshot');
            }
        } catch (err) {
            toast.error('Failed to save offers or snapshot');
            console.error(err);
        }
    };

    // As user changes interestRate or termLength, recalc the read-only "amount"
    const handleOfferChange = (index, field, value) => {
        const updatedOffers = [...newOffers];
        // set the changed field
        updatedOffers[index] = { ...updatedOffers[index], [field]: value };

        // if interestRate or termLength changes, recalc "amount"
        const interestRate = parseFloat(updatedOffers[index].interestRate) || 0;
        if (interestRate > 0) {
            const computedAmount = baseAmount + baseAmount * (interestRate / 100);
            updatedOffers[index].amount = computedAmount.toFixed(2);
        } else {
            updatedOffers[index].amount = '';
        }

        setNewOffers(updatedOffers);
    };

    const handleNotifyCustomer = async () => {
        try {
            const response = await api_post(
                `${process.env.REACT_APP_API_URL}/admin/financing-request/email/${billId}`,
                {}
            );
            if (response.status === 'success') {
                toast.success('Offers sent to customer');
            } else {
                toast.error('Failed to send offers');
                console.error('API responded with:', response);
            }
        } catch (err) {
            toast.error('Failed to send offers');
            console.error(err);
        }
    };

    // Prefill button: fill in 3 offers for 4,6,8 weeks at 15% interest
    const handlePrefill = () => {
        const newData = [
            { termLength: '4', interestRate: '15', amount: '' },
            { termLength: '6', interestRate: '15', amount: '' },
            { termLength: '8', interestRate: '15', amount: '' },
        ].map((offer) => {
            const interestRate = parseFloat(offer.interestRate) || 0;
            const computedAmount = baseAmount + baseAmount * (interestRate / 100);
            return { ...offer, amount: computedAmount.toFixed(2) };
        });
        setNewOffers(newData);
    };

    if (acceptedOffer && isApproved) {
        return (
            <div>
                <h2 className="text-3xl font-medium mb-4">Accepted Offer</h2>
                <div className="border-4 border-green-500 p-4 mb-4">
                    <div className="grid grid-cols-3 gap-4 mb-4">
                        <div>
                            <label className="block font-medium mb-1">Term Length (Weeks)</label>
                            <input
                                type="text"
                                value={acceptedOffer?.termLength || ''}
                                readOnly
                                className="border rounded px-3 py-2 w-full"
                            />
                        </div>
                        <div>
                            <label className="block font-medium mb-1">Interest Rate</label>
                            <input
                                type="text"
                                value={acceptedOffer?.interestRate || ''}
                                readOnly
                                className="border rounded px-3 py-2 w-full"
                            />
                        </div>
                        <div>
                            <label className="block font-medium mb-1">Amount</label>
                            <input
                                type="text"
                                value={acceptedOffer?.amount || ''}
                                readOnly
                                className="border rounded px-3 py-2 w-full"
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div>
            <div className="flex items-center justify-between mb-4">
                <h2 className="text-3xl font-medium">Add Offers</h2>
                <button
                    onClick={handlePrefill}
                    className="bg-gray-300 hover:bg-gray-400 text-black font-medium py-1 px-3 rounded"
                >
                    Prefill 4/6/8 Weeks @15%
                </button>
            </div>
            {newOffers.map((offer, index) => (
                <div key={index} className="grid grid-cols-3 gap-4 mb-4">
                    <div>
                        <label className="block font-medium mb-1" htmlFor={`termLength-${index}`}>
                            Term Length (Weeks)
                        </label>
                        <input
                            type="text"
                            id={`termLength-${index}`}
                            placeholder="e.g. 8"
                            className="border rounded px-3 py-2 w-full"
                            value={offer.termLength}
                            onChange={(e) => handleOfferChange(index, 'termLength', e.target.value)}
                        />
                    </div>
                    <div>
                        <label className="block font-medium mb-1" htmlFor={`interestRate-${index}`}>
                            Interest Rate
                        </label>
                        <input
                            type="text"
                            id={`interestRate-${index}`}
                            placeholder="Interest Rate"
                            className="border rounded px-3 py-2 w-full"
                            value={offer.interestRate}
                            onChange={(e) => handleOfferChange(index, 'interestRate', e.target.value)}
                        />
                    </div>
                    <div>
                        <label className="block font-medium mb-1" htmlFor={`amount-${index}`}>
                            Amount (with Interest)
                        </label>
                        <input
                            type="text"
                            id={`amount-${index}`}
                            className="border rounded px-3 py-2 w-full bg-gray-100"
                            readOnly
                            value={offer.amount}
                        />
                    </div>
                </div>
            ))}
            <div className="flex space-x-4">
                <button
                    onClick={handleSaveOffers}
                    className="bg-blue-400 hover:bg-blue-500 text-white font-medium py-2 px-4 rounded"
                >
                    Save Offers
                </button>
                <button
                    onClick={handleNotifyCustomer}
                    className="bg-yellow-500 hover:bg-yellow-600 text-white font-medium py-2 px-4 rounded"
                >
                    Notify Customer
                </button>
            </div>
        </div>
    );
};

export default FinancingRequestDetail;